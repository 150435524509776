<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-inbox"></i><strong> Produits</strong>
              <div class="card-header-actions">
                <b-button variant="success" @click="onExport" title="Exporter en PDF" class="mr-2" v-b-tooltip.hover>
                  <i class="fa fa-upload"></i>
                </b-button>
              </div>
            </div>
            <div class="text-center" v-if="fetchingProductList">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <div v-else-if="products.length">
              <b-alert variant="warning" class="mx-3" show dismissible
                       v-if="!fetchingUnstoredProductList && unstoredProducts.length">
                <h4 class="alert-heading">Produits non stockés !</h4>
                <p>
                  Les produits suivants n'ont pas encore été stockés dans un entrepôt :
                </p>
                <ul>
                  <li v-for="unstoredProduct in unstoredProducts">
                    {{unstoredProduct}}
                  </li>
                </ul>
                <hr/>
                <p class="mb-0">
                  Pour pouvoir utiliser ces produits vous devez les stocker dans un des
                  <b-link :to="{name: 'ExploitationWarehouses'}">
                    entrepôts de l'exploitation
                  </b-link>
                  .
                </p>
              </b-alert>
              <c-table :table-data="products" :fields="fields" :actions="actions" :per-page=5 striped outlined
                       fixed></c-table>
            </div>
            <p class="text-center" v-else>Aucun produit dans l'exploitation.</p>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import {Api, Toast, asset, env, IO} from "../../../helpers"

  export default {
    name: "ExploitationProducts",
    title: "PIA - Produits",
    data() {
      return {
        products: [],
        unstoredProducts: [],
        fields: [
          {key: 'picture', label: 'Aperçu', asset: this.asset},
          {key: 'culture.name', label: 'Nom', sortable: true},
          {key: 'qty', label: 'Quantité'},
          // {key: 'actions', label: 'Actions'},
        ],
        actions: [
          // {type: 'primary', icon: 'icon-note', title: 'Modifier', handler: this.editCulture},
        ],
        error: null,
        fetchingProductList: true,
        fetchingUnstoredProductList: true,
      }
    },
    created() {
      this.fetchingProductList = true
      Api.get('/exploitation/product/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.products = res.data.data.map(item => ({
              ...item,
              picture: item.culture.picture,
              qty: this.getQty(item)
            }))
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingProductList = false
        })

      this.fetchingUnstoredProductList = true
      Api.get('/exploitation/product/unstored', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.unstoredProducts = res.data.data.map(item => `${item.culture.name} (${this.getQty(item)})`)
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingUnstoredProductList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
    },
    methods: {
      asset(path) {
        return asset(path, env('DEFAULT_CULTURE_PICTURE'))
      },
      getQty(item) {
        return item.quantity ? item.quantity + (item.culture && item.culture.unit ? ' ' + item.culture.unit : '') : ''
      },
      onExport() {
        IO.exportDataTable(this.products, 'products', {
          title: 'Exploitation: ' + this.$store.getters.exploitationName,
          name: 'Liste des produits',
          headers: [
            {title: 'Aperçu', key: 'picture', width: 'auto', asset: this.asset},
            {title: 'Nom', key: 'culture.name', width: '*'},
            {title: 'Quantité', key: 'qty', width: 'auto'},
          ]
        })
      }
    }
  }
</script>

<style scoped>

</style>
